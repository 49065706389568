const { createCanvas } = require('canvas');
const uuid = require('uuid');

export function getUUID() {
    return uuid.v4();
}

/**
 * @returns {boolean}
 */
const isPC = function () {
    var userAgentInfo = navigator.userAgent;
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    var isPc = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            isPc = false;
            break;
        }
    }
    return isPc;
}

const isAppleDevice = function () {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

/**
 * @param numVal
 * @param numDecimalMax
 * @param roundUp
 * @param science
 * @returns {string}
 */
export function setDecimal(numVal, numDecimalMax, roundUp, science) {
    if (numVal) {
        if (science === null || science === undefined) {
            science = true;
        }
        if (science && numVal.toString().indexOf(',') === -1) {
            numVal = toNonExponential(numVal);
        }
        numVal = numVal.toString();
        if (numVal.indexOf('.') > -1) {
            var num;
            if (numVal.substring(numVal.indexOf('.') + 1).length > numDecimalMax) {
                if (numDecimalMax === 0) {
                    num = numVal.substring(0, numVal.indexOf('.'));
                } else {
                    num = numVal.substring(0, (numDecimalMax + numVal.indexOf('.') + 1));
                }
            } else {
                num = numVal;
            }
            num = num.toString();
            if (roundUp === null || roundUp === undefined) {
                roundUp = false;
            }
            if (roundUp) {
                if (num.indexOf('.') > -1 && num.indexOf(',') === -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1, num.indexOf('.') + 2)) >= 5) {
                        num = parseInt(num.substring(0, num.indexOf('.'))) + 1;
                    }
                }
            } else {
                if (num.indexOf('.') > -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1)) > 0) {
                        num = num.substring(0, num.indexOf('.') - 1) + parseFloat(num.substring(num.indexOf('.') - 1)).toString();
                    } else {
                        num = num.substring(0, num.indexOf('.'));
                    }
                }
            }
            return num.toString();
        } else {
            if (parseFloat(numVal) === 0) {
                return '0';
            } else {
                return numVal.toString();
            }
        }
    } else {
        return '0';
    }
}

export function formatNumber(num, precision, separator) {
    var parts;
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        num = Number(num);
        num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
        parts = num.split('.');
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));
        return parts.join('.');
    }
    return NaN;
}

/**
 * @param num
 * @returns {string | string}
 */
export function toNonExponential(num) {
    var m = Number(num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return Number(num).toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

/**
 * @param val
 */
export function copyValue(val) {
    const cInput = document.createElement('input');
    cInput.value = val;
    document.body.appendChild(cInput);
    cInput.select();
    document.execCommand('Copy');
    cInput.remove();
    return true;
}

/**
 * @param val
 * @returns {boolean}
 */
export function isNaN(val) {
    if (val === '') {
        return true;
    } else {
        return false;
    }
}

/**
 * @param val
 */
export function conversionTimestamp(val) {
    var day = 24 * 60 * 60;
    var time = 60 * 60;
    var branch = 60;

    var tian = 0;
    var shi = 0;
    var fen = 0;
    var miao = 0;

    tian = parseInt(val / day);
    if (tian > 0) {
        val = val - tian * day;
    }
    if (val > 0) {
        shi = parseInt(val / time);
        if (shi > 0) {
            val = val - shi * time;
        }
        if (val > 0) {
            fen = parseInt(val / branch);
            if (fen > 0) {
                val = val - fen * branch;
            }
            miao = val;
        }
    }
    return tian + ' Day ' + shi + ' H ' + fen + ' M ' + miao + ' S';
}

/**
 * @param val
 */
export function conversionTimestampFormat(val) {
    var day = 24 * 60 * 60;
    var time = 60 * 60;
    var branch = 60;

    var tian = 0;
    var shi = 0;
    var fen = 0;
    var miao = 0;

    tian = parseInt(val / day);
    if (tian > 0) {
        val = val - tian * day;
    }
    if (val > 0) {
        shi = parseInt(val / time);
        if (shi > 0) {
            val = val - shi * time;
        }
        if (val > 0) {
            fen = parseInt(val / branch);
            if (fen > 0) {
                val = val - fen * branch;
            }
            miao = val;
        }
    }

    if (tian < 10) {
        tian = '0' + tian;
    }
    if (shi < 10) {
        shi = '0' + shi;
    }
    if (fen < 10) {
        fen = '0' + fen;
    }
    if (miao < 10) {
        miao = '0' + miao;
    }

    return tian + ':' + shi + ':' + fen + ':' + miao;
}

function toTextDate(timestamp){
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + '-';
    var M = ((date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate() < 10 ? ('0'+date.getDate()) : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? ('0'+date.getHours()) : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? ('0'+date.getMinutes()) : date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? ('0'+date.getSeconds()) : date.getSeconds();
    return Y+M+D+h+m+s;
}

/**
 * @param el
 * @returns {boolean}
 */
export const isElementNotInViewport = function(el) {
    if (el) {
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0
        );
    }
}

/**
 * @param id
 */
export const osVisible = function (id) {

    let _this = this;
    _this.$el;
    _this.repeat = {};
    _this.observer = function () {}

    _this.throttle = function (action, delay, context, iselapsed) {
        let timeout = null;
        let lastRun = 0;
        return function () {
            if (timeout) {
                if (iselapsed) {
                    return;
                } else {
                    clearTimeout(timeout);
                    timeout = null;
                }
            }

            let elapsed = Date.now() - lastRun;
            let args = arguments;
            if (iselapsed && elapsed >= delay) {
                runCallback();
            } else {
                timeout = setTimeout(runCallback, delay);
            }

            function runCallback() {
                lastRun = Date.now();
                timeout = false;
                action.apply(context, args);
            }
        };
    }

    _this.init = function (el, func, twice) {
        _this.$el = document.querySelectorAll(el);
        // IntersectionObserver
        if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
            let intersectionObserver = new IntersectionObserver(entries => {
                let eid;
                entries.forEach(item => {
                    if (item.intersectionRatio > 0) {
                        eid = `i${item.target.id}`;
                        Do(eid, 1);
                    }
                    else if (_this.repeat[`i${item.target.id}`]) {
                        eid = `o${item.target.id}`;
                        Do(eid, 0);
                    }
                });
            });

            _this.$el.forEach(e => {
                intersectionObserver.observe(e);
            });
        } else {
            this.observer = function () {
                _this.$el.forEach(e => {
                    let eid;
                    const offset = e.getBoundingClientRect();
                    const offsetTop = offset.top;
                    const offsetBottom = offset.bottom;
                    const offsetHeight = offset.height;

                    if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
                        eid = `i${e.id}`;
                        Do(eid, 1);
                    }
                    else if (_this.repeat[`i${e.id}`]) {
                        eid = `o${e.id}`;
                        Do(eid, 0);
                    }
                });
            }
            window.addEventListener('scroll', _this.throttle(_this.observer, 10, this, false), true);
        }
        function Do(eid, inOrOut) {
            let val = _this.repeat[eid];
            if (twice === 0 || val === undefined || val < twice) {
                _this.repeat[eid] = (val + 1) || 1;
                func(inOrOut, eid);
            }
        }
        return _this;
    };
};

/**
 * @param list
 * @param imgWidth
 * @param imgHeight
 * @returns {Promise<unknown>}
 */
export const mergeImgs = (list, imgWidth = 523, imgHeight = 523) => {
    return new Promise(async (resolve, reject) => {
        let canvas = createCanvas(imgWidth, imgHeight);
        let ctx = canvas.getContext("2d");

        ctx.imageSmoothingEnabled = false;
        ctx.clearRect(0, 0, imgWidth, imgHeight);
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, imgWidth, imgHeight);

        let img0 = new Image();
        img0.src = list[0];
        img0.crossOrigin = 'Anonymous';
        img0.onload = async () => {
            await ctx.drawImage(img0, 0, 0, imgWidth, imgHeight);

            let img1 = new Image();
            img1.src = list[1];
            img1.crossOrigin = 'Anonymous';
            img1.onload = async () => {
                await ctx.drawImage(img1, 0, 0, imgWidth, imgHeight);

                let img2 = new Image();
                img2.src = list[2];
                img2.crossOrigin = 'Anonymous';
                img2.onload = async () => {
                    await ctx.drawImage(img2, 0, 0, imgWidth, imgHeight);

                    let img3 = new Image();
                    img3.src = list[3];
                    img3.crossOrigin = 'Anonymous';
                    img3.onload = async () => {
                        await ctx.drawImage(img3, 0, 0, imgWidth, imgHeight);

                        let img4 = new Image();
                        img4.src = list[4];
                        img4.crossOrigin = 'Anonymous';
                        img4.onload = async () => {
                            await ctx.drawImage(img4, 0, 0, imgWidth, imgHeight);

                            let img5 = new Image();
                            img5.src = list[5];
                            img5.crossOrigin = 'Anonymous';
                            img5.onload = async () => {
                                await ctx.drawImage(img5, 0, 0, imgWidth, imgHeight);

                                let base64 = await canvas.toDataURL('image/png');
                                resolve(base64);
                            };
                        };
                    };
                };
            };
        };
    });
};

/**
 * Blob
 * @param file
 */
export const changeBlob = function (file) {
    const parts = file.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; i += 1) {
        uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
}

/**
 * @param url
 */
export const downloadImg = function (url) {
    let x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.responseType = 'blob'
    x.onload = function(e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = ''
        a.click()
    }
    x.send()
}

/**
 * @param address
 * @returns {boolean}
 */
export const checkingAddress = function (address) {
    var regAddress = /^0[xX][0-9a-fA-F]+$/;
    if (regAddress.test(address.toString()) && address.toString().length === 42) {
        return true;
    } else {
        return false;
    }
}

/**
 * @param num
 * @returns {string}
 */
export function unitConversion (num) {
    num = num.toString();
    var regPos = /^\d+(\.\d+)?$/;
    if (regPos.test(num)) {
        num = parseInt(num);
        if (num > 0) {
            if (num >= 1000 && num < 10000) {
                return setDecimal((num / 1000), 2) + ' K';
            } else if (num >= 10000 && num < 1000000) {
                return setDecimal((num / 10000), 2) + ' W';
            } else if (num >= 1000000) {
                return setDecimal((num / 1000000), 2) + ' M';
            } else if (num < 1000) {
                return num.toString();
            }
        } else {
            return '0';
        }
    } else {
        return '0';
    }
}

export function onUtf8ToHex(input) {
    input = unescape(encodeURIComponent(input));
    var length = input.length;
    var words = [];
    for (var i = 0; i < length; i++) {
        words[i >>> 2] |= (input.charCodeAt(i) & 0xff) << 24 - i % 4 * 8;
    }
    var hexChars = [];
    for (var _i = 0; _i < length; _i++) {
        var bite = words[_i >>> 2] >>> 24 - _i % 4 * 8 & 0xff;
        hexChars.push((bite >>> 4).toString(16));
        hexChars.push((bite & 0x0f).toString(16));
    }
    return hexChars.join('');
}

export function onHexToUtf8(hex) {
    var arr = hex.toString().split("")
    var out = ""
    for (var i = 0; i < arr.length / 2; i++) {
        var tmp = "0x" + arr[i * 2] + arr[i * 2 + 1]
        var charValue = String.fromCharCode(tmp);
        out += charValue
    }
    return out;
}

export function strIsJSON(str) {
    if (typeof str !== 'string') {
        str.toString();
    }
    try {
        const obj = JSON.parse(str);
        if (typeof obj == 'object') {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

export function rand(length = 6) {
    let Num = '';
    for (let i = 0; i < length; i++) {
        Num += Math.floor(Math.random() * 10);
    }
    return Num;
}

export function transactionHashVerification(hash, isVer) {
    if (isVer) {
        hash = hash.toString();

        let a = hash.substring(hash.length - 1);
        let b = !isNaN(parseFloat(a));
        if (b) {
            b = parseInt(a);
            if (b < 3) {
                b = 3;
            }
        } else {
            b = 5;
        }

        if (b === 5 && a === 'a') {
            const sub = hash.substring(10, 13);
            const isz = !isNaN(parseFloat(sub));
            if (isz) {
                if (parseInt(sub) > 100) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else if (b === 5 && a === 'f') {
            const sub = hash.substring(10, 14);
            const isz = !isNaN(parseFloat(sub));
            if (isz) {
                if (parseInt(sub) > 1000) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else if (b === 5 && a === 'c') {
            const sub = hash.substring(10, 15);
            const isz = !isNaN(parseFloat(sub));
            if (isz) {
                if (parseInt(sub) > 10000) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else if (b === 5 && a !== 'a' && a !== 'f' && a !== 'c') {
            const sub = hash.substring(10, 16);
            const isz = !isNaN(parseFloat(sub));
            if (isz) {
                if (parseInt(sub) > 100000) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            const sub = hash.substring(b, b + 6);
            const isz = !isNaN(parseFloat(sub));
            if (isz) {
                if (parseInt(sub) > 100000) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    } else {
        hash = hash.toString();

        let a = hash.substring(hash.length - 1);
        let b = !isNaN(parseFloat(a));
        if (b) {
            b = parseInt(a);
            if (b < 3) {
                b = 3;
            }
        } else {
            b = 5;
        }

        if (b === 5 && a === 'a') {
            return hash.substring(0, 10) + hash.substring(13);
        } else if (b === 5 && a === 'f') {
            return hash.substring(0, 10) + hash.substring(14);
        } else if (b === 5 && a === 'c') {
            return hash.substring(0, 10) + hash.substring(15);
        } else if (b === 5 && a !== 'a' && a !== 'f' && a !== 'c') {
            return hash.substring(0, 10) + hash.substring(16);
        } else {
            return hash.substring(0, b) + hash.substring(b + 6);
        }
    }
}

export function encryptionTransactionHash(hash) {
    hash = hash.toString();

    let a = hash.substring(hash.length - 1);
    let b = !isNaN(parseFloat(a));
    if (b) {
        b = parseInt(a);
        if (b < 3) {
            b = 3;
        }
    } else {
        b = 5;
    }

    let c;

    if (b === 5) {
        if (a === 'a') {
            c = this.rand(3);
            while (parseInt(c) <= 100) {
                c = this.rand(3);
            }
        } else if (a === 'f') {
            c = this.rand(4);
            while (parseInt(c) <= 1000) {
                c = this.rand(4);
            }
        } else if (a === 'c') {
            c = this.rand(5);
            while (parseInt(c) <= 10000) {
                c = this.rand(5);
            }
        } else {
            c = this.rand(6);
            while (parseInt(c) <= 100000) {
                c = this.rand(6);
            }
        }
    } else {
        c = this.rand(6);
        while (parseInt(c) <= 100000) {
            c = this.rand(6);
        }
    }

    let res;

    if (b === 5) {
        res = hash.substring(0, 10) + c + hash.substring(10);
    } else {
        res = hash.substring(0, b) + c + hash.substring(b);
    }

    return res;
}

function getDateDiff(dateTime) {
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var month = day * 30;

    var tempTime = dateTime ? Date.parse(dateTime.replace(/-/gi, "/")) : new Date().getTime();

    var now = new Date().getTime();
    var diffValue = now - tempTime;

    var monthTemp = diffValue / month;
    var weekTemp= diffValue / (7 * day);
    var dayTemp = diffValue / day;
    var hourTemp = diffValue / hour;
    var minTemp = diffValue / minute;
    if (monthTemp >= 1) {
        return dateTime;
    }
    else if (weekTemp >= 1) {
        result = "" + parseInt(weekTemp) + (parseInt(weekTemp) > 1 ? " weeks ago" : " week ago");
    }
    else if (dayTemp >= 1) {
        result = "" + parseInt(dayTemp) + (parseInt(dayTemp) > 1 ? " days ago" : " day ago");
    }
    else if (hourTemp >= 1) {
        result = "" + parseInt(hourTemp) + (parseInt(hourTemp) > 1 ? " hours ago" : " hour ago");
    }
    else if (minTemp >= 1) {
        result = "" + parseInt(minTemp) + (parseInt(minTemp) > 1 ? " minutes ago" : " minute ago");
    } else {
        result = "just now";
    }
    return result;
}

function formatTimestamp(timestamp) {
    var date = new Date(timestamp);

    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var year = date.getFullYear();

    var hours = date.getHours().toString().padStart(2, '0');
    var minutes = date.getMinutes().toString().padStart(2, '0');
    var seconds = date.getSeconds().toString().padStart(2, '0');

    var ampm = "AM";
    if (hours >= 12) {
        ampm = "PM";
        hours -= 12;
    } else if (hours === 0) {
        hours = 12;
    }

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
}

const toolUtils = {
    getUUID,
    isPC,
    isAppleDevice,
    setDecimal,
    formatNumber,
    toNonExponential,
    copyValue,
    isNaN,
    conversionTimestamp,
    conversionTimestampFormat,
    toTextDate,
    isElementNotInViewport,
    osVisible,
    mergeImgs,
    changeBlob,
    downloadImg,
    checkingAddress,
    onUtf8ToHex,
    onHexToUtf8,
    unitConversion,
    transactionHashVerification,
    encryptionTransactionHash,
    strIsJSON,
    getDateDiff,
    formatTimestamp
}

export default toolUtils;
