<template>
    <div class="protocol_top">
        <div class="protocol_top_div">
            <div class="protocol_top_div_01">
                <img class="protocol_top_div_01_img" src="../assets/images/logo_nav.png"/>
            </div>

            <div class="protocol_top_div_02">
                <div class="protocol_top_div_021">
                    <img src="../assets/images/logo_mapbtn.png"/>
                    <div>Map Protocol</div>
                </div>
                <div class="protocol_top_div_022" @click="openConnectWallet()">
                    {{$store.state.accounts.length === 0 ? 'Connect Wallet' : cutAccount($store.state.accounts[0], 5)}}
                </div>
            </div>
        </div>

        <ConnectWallet/>
        <MetaDialog/>
    </div>
</template>

<script>
    import ConnectWallet from "../components/ConnectWallet";
    import MetaDialog from "../components/MetaDialog";
    import {cutAccount} from "../tools";
    import {
        debug
    } from "../config";
    import * as metaMaskTools from "../tools/chain";
    import {setProjectConfig} from "../tools/projectConfig";

    export default {
        name: "Top",
        components: { ConnectWallet, MetaDialog },
        data() {
            return {
                debug,
            }
        },
        watch: {},
        created() {
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {
            cutAccount(text, num, isXing) {
                return cutAccount(text, num, isXing);
            },
            openConnectWallet() {
                this.$store.commit('setState', {
                    connectWalletShow: true
                })
            },
        }
    }
</script>

<style lang="less">
    .protocol_top {
        .protocol_top_div {
            height: 120px;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 1023px){
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
            }

            .protocol_top_div_01 {
                .protocol_top_div_01_img {
                    width: 294px;
                }
            }
            .protocol_top_div_02 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;

                .protocol_top_div_021 {
                    width: 180px;
                    height: 56px;
                    background: #040404;
                    border-radius: 16px;
                    cursor: pointer;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-evenly;
                    align-items: center;

                    img {
                        width: 24px;
                    }
                    div {
                        font-family: SpaceGrotesk, SpaceGrotesk;
                        font-weight: bold;
                        font-size: 16px;
                        color: #C5F646;
                        line-height: 21px;
                        text-align: right;
                        font-style: normal;
                    }

                    @media (max-width: 1023px){
                        border: 1px solid #272727;
                    }
                }
                .protocol_top_div_022 {
                    width: 180px;
                    height: 56px;
                    background: #040404;
                    border-radius: 16px;
                    cursor: pointer;

                    font-family: SpaceGrotesk, SpaceGrotesk;
                    font-weight: bold;
                    font-size: 16px;
                    color: #C5F646;
                    line-height: 56px;
                    text-align: center;
                    font-style: normal;

                    margin-left: 25px;

                    @media (max-width: 1023px){
                        border: 1px solid #272727;
                    }
                }
            }
        }
    }
</style>
