import { debug } from "../config";
import store from "../store";

import abiTheAwakeningOfMapNft from '../abi/abiTheAwakeningOfMapNft';



/* main */
// Map - imgUrl: https://bafybeidcve3pgw3r2aezszgod7dlzymcusmvpoczpe7m7b2qhlqlodwzia.ipfs.nftstorage.link/TaomNFT.png
let openUrlSLD = 'maposcan.io';
let addressList = [
    '0x790F77F6507e0da03197dd761Db61597AA9Cb29A', // theAwakeningOfMapNft
];
let abiList = [
    abiTheAwakeningOfMapNft,
];



const setProjectConfig = async () => {
    // Map
    openUrlSLD = 'maposcan.io';
    addressList = [
        '0x790F77F6507e0da03197dd761Db61597AA9Cb29A', // theAwakeningOfMapNft
    ];
    abiList = [
        abiTheAwakeningOfMapNft,
    ];

    // Bevm
    if (parseInt(store.state.networkIDstring) === 11501) {
        openUrlSLD = 'scan-mainnet.bevm.io';
        addressList = [];
        abiList = [];
    }

    // B²
    if (parseInt(store.state.networkIDstring) === 223) {
        openUrlSLD = 'explorer.bsquared.network';
        addressList = [];
        abiList = [];
    }



    /* test */
    if (debug) {
        // Map
        openUrlSLD = 'testnet.maposcan.io';
        addressList = [
            '0xe514e54A7A948f58a21f7Fd1b20FBac6fFD611a3', // theAwakeningOfMapNft
        ];
        abiList = [
            abiTheAwakeningOfMapNft,
        ];

        // Bevm
        if (parseInt(store.state.networkIDstring) === 1502) {
            openUrlSLD = 'scan-canary-testnet.bevm.io';
            addressList = [];
            abiList = [];
        }

        // B²
        if (parseInt(store.state.networkIDstring) === 223) {
            openUrlSLD = 'explorer.bsquared.network';
            addressList = [];
            abiList = [];
        }
    }
}



export {
    openUrlSLD,
    addressList,
    abiList,
    setProjectConfig
}
