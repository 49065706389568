import store from "../store";
import toolUtils from "./toolUtils";

var mixin1 = {
    computed: {
        isFollow() {
            return this.$store.state.accounts[0];
        },
        networkID() {
            return this.$store.state.networkIDstring;
        }
    },
    watch: {
        isFollow(newVal, oldVal) {
            console.log("newVal", newVal, "oldVal", oldVal);
        },
        networkID(newVal, oldVal) {
            console.log("newVal", newVal, "oldVal", oldVal);
        }
    },
    methods: {
        onAccountsChanged: function () {
            var _this = this;

            if (window.ethereum) {
                window.ethereum.on('accountsChanged', function (accounts) {
                    _this.onRefresh(accounts);
                });

                window.ethereum.on('networkChanged', function (networkIDstring) {
                    if (networkIDstring != null) {
                        if (
                            parseInt(networkIDstring) === 11501 ||
                            parseInt(networkIDstring) === 1502 ||
                            parseInt(networkIDstring) === 22776 ||
                            parseInt(networkIDstring) === 212 ||
                            parseInt(networkIDstring) === 223
                        ) {
                            store.commit("setState", {
                                networkIDstring,
                            });
                        }

                        _this.initPageData();
                    }
                });
            }

            if (window.okxwallet) {
                window.okxwallet.on('accountsChanged', function (accounts) {
                    _this.onRefresh(accounts);
                });

                window.okxwallet.on('networkChanged', function (networkIDstring) {
                    if (networkIDstring != null) {
                        if (
                            parseInt(networkIDstring) === 11501 ||
                            parseInt(networkIDstring) === 1502 ||
                            parseInt(networkIDstring) === 22776 ||
                            parseInt(networkIDstring) === 212 ||
                            parseInt(networkIDstring) === 223
                        ) {
                            store.commit("setState", {
                                networkIDstring,
                            });
                        }

                        _this.initPageData();
                    }
                });
            }
        }
    }
}

export { mixin1 }
