import store from "../store";
import Web3 from "web3";

/*
 * metaMask
 */
const initMetaMask = () => {
    return new Promise((resolve, reject) => {
        if (window.ethereum) {
            const ethereum = window.ethereum;
            window.globalWeb3 = new Web3(ethereum);
            ethereum.request({method: 'eth_requestAccounts'}).then((accounts) => {
                resolve(accounts);
            }).catch(() => {
                reject('User denied account access...');
            });
        } else {
            reject('Please install MetaMask!');

            setTimeout(() => {
                window.open('https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=ext_app_menu', '_blank');
            }, 2000);
        }
    });
}

const connectMetaMask = async () => {
    let accounts = [];
    if (window.ethereum) {
        await initMetaMask();
        accounts = window.globalWeb3.eth.accounts;
    }
    if (!accounts || !accounts[0]) {
        accounts = await initMetaMask();
    }
    if (accounts.length === 0) {
        throw new Error('no accounts');
    }
    store.commit("setState", {
        accounts,
        connectType: 0,
    });
    localStorage.setItem('accounts', accounts[0]);
}

/*
 * OKX
 */
const initOKX = () => {
    return new Promise((resolve, reject) => {
        if (window.okxwallet) {
            const okxwallet = window.okxwallet;
            window.globalWeb3 = new Web3(okxwallet);
            okxwallet.request({method: 'eth_requestAccounts'}).then((accounts) => {
                resolve(accounts);
            }).catch(() => {
                reject('User denied account access...');
            });
        } else {
            reject('OKX is installed!');

            setTimeout(() => {
                window.open('https://chromewebstore.google.com/detail/%E6%AC%A7%E6%98%93-web3-%E9%92%B1%E5%8C%85/mcohilncbfahbmgdjkbpemcciiolgcge', '_blank');
            }, 2000);
        }
    });
}

const connectOKX = async () => {
    let accounts = [];
    if (window.okxwallet) {
        await initOKX();
        accounts = window.globalWeb3.eth.accounts;
    }
    if (!accounts || !accounts[0]) {
        accounts = await initOKX();
    }
    if (accounts.length === 0) {
        throw new Error('no accounts');
    }
    store.commit("setState", {
        accounts,
        connectType: 0,
    });
    localStorage.setItem('accounts', accounts[0]);
}

/*
 * Gate
 */
const initGate = () => {
    return new Promise((resolve, reject) => {
        if (window.gatewallet) {
            const gatewallet = window.gatewallet;
            window.globalWeb3 = new Web3(gatewallet);
            gatewallet.request({method: 'eth_requestAccounts'}).then((accounts) => {
                resolve(accounts);
            }).catch(() => {
                reject('User denied account access...');
            });
        } else {
            reject('Gate is installed!');

            setTimeout(() => {
                window.open('https://chromewebstore.google.com/detail/gate-wallet/cpmkedoipcpimgecpmgpldfpohjplkpp?hl=zh-CN&utm_source=ext_sidebar', '_blank');
            }, 2000);
        }
    });
}

const connectGate = async () => {
    let accounts = [];
    if (window.gatewallet) {
        await initGate();
        accounts = window.globalWeb3.eth.accounts;
    }
    if (!accounts || !accounts[0]) {
        accounts = await initGate();
    }
    if (accounts.length === 0) {
        throw new Error('no accounts');
    }
    store.commit("setState", {
        accounts,
        connectType: 0,
    });
    localStorage.setItem('accounts', accounts[0]);
}

export {
    connectMetaMask,
    connectOKX,
    connectGate
}
