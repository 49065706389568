// debug true:test  false:main;
const devUrl = ["localhost", "127.0.0.1"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

// let debug = getEnv();

// let debug = true; // test
let debug = false; // main

export {
    debug,
}
