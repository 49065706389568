<template>
    <div id="theAwakeningOfMapNft_page" class="theAwakeningOfMapNft_page">
        <div class="theAwakeningOfMapNft_page_01">
            <Top/>
        </div>

        <img id="theAwakeningOfMapNft_page_bj" class="theAwakeningOfMapNft_page_bj" src="../../../assets/images/background.png"/>

        <div id="theAwakeningOfMapNft_page_02" class="theAwakeningOfMapNft_page_02">
            <div id="theAwakeningOfMapNft_page_02s" class="theAwakeningOfMapNft_page_02s">
                <div class="theAwakeningOfMapNft_page_02_1">
                    <div id="theAwakeningOfMapNft_page_02_11" class="theAwakeningOfMapNft_page_02_11">
<!--                        <img :src="mintState === 1 ? require('../../../assets/images/NFT2.png') : require('../../../assets/images/NFT1.png')"/>-->
                        <img @load="onImageLoad" src="../../../assets/images/NFT2.png"/>
                    </div>

                    <div id="theAwakeningOfMapNft_page_02_12" class="theAwakeningOfMapNft_page_02_12">
                        <div>
                            <div class="theAwakeningOfMapNft_page_02_121">
                                MAPO:
                            </div>
                            <div class="theAwakeningOfMapNft_page_02_122">
                                The Awakening Of MAP
                            </div>

                            <div class="theAwakeningOfMapNft_page_02_123">
                                To celebrate the completion of the main network switch of Map Protocol, Map Protocol collaborated with Bitsea to launch the commemorative NFT "MAPO:The Awakening Of MAP".
                            </div>
                        </div>

                        <div class="theAwakeningOfMapNft_page_02_124">
                            <div class="theAwakeningOfMapNft_page_02_1241">
                                <div class="theAwakeningOfMapNft_page_02_12411">
                                    Start Time:
                                </div>
                                <div class="theAwakeningOfMapNft_page_02_12412">
<!--                                    {{this.startTime === 0 ? '&#45;&#45;' : new Date(this.startTime * 1000).toUTCString()}}-->
                                    undetermined
                                </div>
                            </div>
                            <div class="theAwakeningOfMapNft_page_02_1241">
                                <div class="theAwakeningOfMapNft_page_02_12411">
                                    End Time:
                                </div>
                                <div class="theAwakeningOfMapNft_page_02_12412">
<!--                                    {{this.endTime === 0 ? '&#45;&#45;' : new Date(this.endTime * 1000).toUTCString()}}-->
                                    undetermined
                                </div>
                            </div>
                        </div>

                        <div v-show="mintState === 0" class="theAwakeningOfMapNft_page_02_125" style="background: #8D9092; cursor: not-allowed;">
                            Whating Start
                        </div>

                        <div v-show="mintState === 1 && userMint < maxMint" class="theAwakeningOfMapNft_page_02_125" @click="toNftMint()">
                            Mint ({{maxMint}}/address)
                        </div>

                        <div v-show="mintState === 1 && userMint >= maxMint" class="theAwakeningOfMapNft_page_02_125" style="background: #8D9092; cursor: not-allowed;">
                            Minted
                        </div>

                        <div v-show="mintState === 2" class="theAwakeningOfMapNft_page_02_125" style="background: #8D9092; cursor: not-allowed;">
                            End
                        </div>

                        <div class="theAwakeningOfMapNft_page_02_126">
                            <div @click="toGetMapo()" class="theAwakeningOfMapNft_page_02_12611" style="cursor: pointer; text-decoration: underline;">
                                Get MAPO >
                            </div>
                            <div class="theAwakeningOfMapNft_page_02_12611">
                                Minted: {{allMinted}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="theAwakeningOfMapNft_page_02_2">
                    As the first commemorative NFT launched by Map Protocol, "MAPO:The Awakening of MAP" aims to express gratitude to every supporter and sincerely invite every new user to join the community and create a better future together.
                    {{'\n\n'}}
                    This series of NFTs is not only a precious gift, but also a witness to the completion of the Map Protocol main network switch, enjoying special status and identity recognition.
                    {{'\n\n'}}
                    Any user can Free Mint in a limited period of time, without any limit, until the end.
                </div>
            </div>

            <div class="theAwakeningOfMapNft_page_02_3">
                <div class="theAwakeningOfMapNft_page_02_30">
                    <div class="theAwakeningOfMapNft_page_02_31">
                        <div class="theAwakeningOfMapNft_page_02_311">
                            About MAP Protocol
                        </div>
                        <div class="theAwakeningOfMapNft_page_02_312">
                            MAP Protocol, as a Bitcoin Layer 2 network that focuses on cross chain interoperability, provides an infrastructure for interoperability between blockchain based assets, storage, and computing built on ZK lightweight client technology. Its interoperability solution is point-to-point and does not require any trusted third-party entities. Therefore, it transforms isolated Bitcoin Layer 2 networks or blockchains using third-party bridges into an integrated Bitcoin ecosystem decentralized application development platform, as well as an entry gateway for Web3 users.
                        </div>
                        <div class="theAwakeningOfMapNft_page_02_313" @click="toAboutMAPProtocol()">
                            Visit
                        </div>
                    </div>

                    <div class="theAwakeningOfMapNft_page_02_32">
                        <div class="theAwakeningOfMapNft_page_02_321">
                            About Bitsea
                        </div>
                        <div class="theAwakeningOfMapNft_page_02_322">
                            Bitsea is committed to building a BTC L2 asset issuance, launch, and trading platform. Users can participate in the launch and trading of protocol assets such as Erc20, 721, 1155, 404, etc. on Bitsea, and enjoy a smooth experience and extremely low fees. Now supports BEVM and Map Protocol, and will soon support B ²  Network and Merlin Chain.
                        </div>
                        <div class="theAwakeningOfMapNft_page_02_323" @click="toAboutBitsea()">
                            Visit
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mixin1 } from "../../../tools/mixins";
    import toolUtils from "../../../tools/toolUtils";
    import * as metaMaskTools from "../../../tools/chain";

    import Top from "../../../components/Top";
    import {setProjectConfig} from "../../../tools/projectConfig";
    import {debug} from "../../../config";
    import {
        BigNumber,
        BigNumberStr,
        BigNumberMul,
        BigNumberDiv,
        BigNumberAdd,
        BigNumberSub,
    } from '../../../tools';

    export default {
        name: "Index",
        components: {Top},
        mixins: [ mixin1 ],
        data() {
            return {
                nftIndex: 0,
                mintState: 0,
                maxMint: 0,
                userMint: 0,
                userBalance: 0,
                allMinted: 0,
                startTime: 0,
                endTime: 0,
                nftPrice: 0
            }
        },
        watch: {},
        created() {
            setTimeout(() => {
                this.onAccountsChanged();
            }, 1000);
        },
        mounted() {
            setTimeout(() => {
                this.initPageData();
            }, 500);

            window.onresize = () => {
                return (() => {
                    this.setTheAwakeningOfMapNftPageHeight();
                })();
            }

            setTimeout(() => {
                this.setTheAwakeningOfMapNftPageHeight();
            }, 10);
            setTimeout(() => {
                this.setTheAwakeningOfMapNftPageHeight();
            }, 500);

            this.pageRefresh();
        },
        destroyed() {
        },
        methods: {
            async onRefresh(accounts) {
                this.$store.commit('setState', {
                    accounts
                });

                setTimeout(async () => {
                    await this.setTheAwakeningOfMapNftPageHeight();
                }, 10);
            },
            async initPageData() {
                let chainId = 22776;
                if (debug) {
                    chainId = 212;
                }
                this.$store.commit("setState", {
                    networkIDstring: parseInt(chainId),
                });

                await setProjectConfig().then((res) => {});

                await metaMaskTools.initParams().then(() => {});
            },
            isPC() {
                return toolUtils.isPC();
            },
            setTheAwakeningOfMapNftPageHeight() {
                // let divWidth = document.getElementById("theAwakeningOfMapNft_page_02s").clientWidth;
                let divHeight = document.getElementById("theAwakeningOfMapNft_page_02s").clientHeight;

                let bjHeight = divHeight + 320;
                $('#theAwakeningOfMapNft_page_bj').animate({ height : bjHeight }, 0);

                let logoHeight = document.getElementById("theAwakeningOfMapNft_page_02_11").clientHeight;
                $('#theAwakeningOfMapNft_page_02_12').animate({ minHeight : logoHeight }, 0);
            },
            onImageLoad() {
                setTimeout(() => {
                    let logoHeight = document.getElementById("theAwakeningOfMapNft_page_02_11").clientHeight;
                    $('#theAwakeningOfMapNft_page_02_12').animate({ minHeight : logoHeight }, 0);
                }, 300);
            },
            toAboutBitsea() {
                window.open("https://bitsea.ink/#/home", '_blank');
            },
            toAboutMAPProtocol() {
                window.open("https://www.mapprotocol.io/", '_blank');
            },
            toGetMapo() {
                window.open("https://www.mapprotocol.io/get-map", '_blank');
            },
            async pageRefresh() {
                await this.getInfoPici();
                await this.getMaxMintCount();
                await this.getUserMintCount();
                await this.getNftState();
                await this.getBalance();

                setTimeout(async () => {
                    await this.pageRefresh();
                }, 30000);
            },
            async getBalance() {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        await metaMaskTools.getBalance(this.$store.state.accounts[0]).then(async (balance) => {
                            // console.log("balance: " + balance);

                            if (balance) {
                                this.userBalance = parseInt(balance);
                            } else {
                                this.userBalance = 0;
                            }
                        });
                    } else {
                        this.userBalance = 0;
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async toNftMint() {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        await this.getMaxMintCount();
                        await this.getUserMintCount();

                        if (this.userMint >= this.maxMint) {
                            this.$message.warning('You have used!');
                            return;
                        } else {
                            let price = 0;
                            if (this.nftPrice > 0 && parseFloat(BigNumberDiv(this.nftPrice, 1e18)) >= parseFloat(BigNumberDiv(this.userBalance, 1e18))) {
                                this.$message.warning('Insufficient expenses!');
                                return;
                            } else {
                                if (this.nftPrice > 0) {
                                    price = BigNumberMul(this.nftPrice, 1);
                                }

                                await metaMaskTools.toNftMint(this.nftIndex, [1], price).then(async (res) => {
                                    console.log(res);

                                    await this.getBalance();
                                    await this.getInfoPici();
                                    await this.getMaxMintCount();
                                    await this.getUserMintCount();
                                    await this.getNftState();
                                });
                            }
                        }
                    } else {
                        this.$message.warning('Please connect the wallet!');

                        await this.initPageData();

                        this.$store.commit('setState', {
                            connectWalletShow: true
                        })
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async getInfoPici() {
                try {
                    await metaMaskTools.getInfoPici(this.nftIndex, [1]).then(async (res) => {
                        // console.log("piciInfo: " + res);

                        if (res) {
                            this.allMinted = res[0][2];
                            this.startTime = res[0][3];
                            this.endTime = res[0][4];
                            this.nftPrice = parseInt(res[0][5]);
                        } else {
                            this.allMinted = 0;
                            this.startTime = 0;
                            this.endTime = 0;
                            this.nftPrice = 0;
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            async getMaxMintCount() {
                try {
                    await metaMaskTools.getMaxMintCount(this.nftIndex, []).then(async (num) => {
                        // console.log("max: " + num);

                        if (num) {
                            this.maxMint = parseInt(num);
                        } else {
                            this.maxMint = 0;
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            },
            async getUserMintCount() {
                try {
                    if (this.$store.state.accounts.length > 0) {
                        await metaMaskTools.getUserMintCount(this.nftIndex, [this.$store.state.accounts[0]]).then(async (num) => {
                            // console.log("mint: " + num);

                            if (num) {
                                this.userMint = parseInt(num);
                            } else {
                                this.userMint = 0;
                            }
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            },
            async getNftState() {
                try {
                    await metaMaskTools.getNftState(this.nftIndex, []).then(async (res) => {
                        // console.log("state: " + res);

                        if (res) {
                            this.mintState = parseInt(res);
                        } else {
                            this.mintState = 0;
                        }
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }
</script>

<style lang="less">
    .theAwakeningOfMapNft_page {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        .theAwakeningOfMapNft_page_01 {
            z-index: 1;
            width: 70%;
            position: absolute;
            top: 0;
            left: 15%;

            @media (max-width: 1023px) {
                width: 80%;
                left: 10%;
            }
        }

        .theAwakeningOfMapNft_page_bj {
            position: absolute;
            width: 100%;
            /*height: 100%;*/
            top: 0;
            /*bottom: 0;*/
            left: 0;
        }

        .theAwakeningOfMapNft_page_02::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .theAwakeningOfMapNft_page_02 {
            /*overflow-y: auto;*/
            position: absolute;
            width: 100%;
            top: 180px;
            bottom: 0;

            .theAwakeningOfMapNft_page_02s {
                width: 100%;
                .theAwakeningOfMapNft_page_02_1 {
                    width: 70%;
                    margin-left: 15%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: 1023px){
                        width: 80%;
                        margin-left: 10%;

                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: center;
                        align-items: flex-start;
                    }

                    .theAwakeningOfMapNft_page_02_11 {
                        width: 45%;
                        img {
                            width: 100%;
                        }

                        @media (max-width: 1023px){
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }

                    .theAwakeningOfMapNft_page_02_12 {
                        width: 45%;

                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-items: flex-start;
                        justify-content: space-between;

                        @media (max-width: 1023px){
                            width: 100%;
                            min-height: 500px !important;
                        }
                        .theAwakeningOfMapNft_page_02_121 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 45px;
                            color: #F8F8F8;
                            line-height: 58px;
                            text-align: left;
                            font-style: normal;

                            -webkit-background-clip: text;

                            @media (max-width: 1023px){
                                font-size: 36px;
                            }
                        }
                        .theAwakeningOfMapNft_page_02_122 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 45px;
                            color: #F8F8F8;
                            line-height: 58px;
                            text-align: left;
                            font-style: normal;

                            @media (max-width: 1023px){
                                font-size: 36px;
                            }
                        }
                        .theAwakeningOfMapNft_page_02_123 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: 400;
                            font-size: 16px;
                            color: #F8F8F8;
                            line-height: 24px;
                            text-align: left;
                            font-style: normal;
                            margin-top: 20px;
                            margin-bottom: 10px;
                        }
                        .theAwakeningOfMapNft_page_02_124 {
                            width: 100%;
                            .theAwakeningOfMapNft_page_02_1241 {
                                width: 100%;

                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                justify-content: space-between;
                                align-items: stretch;

                                .theAwakeningOfMapNft_page_02_12411 {
                                    font-family: SpaceGrotesk, SpaceGrotesk;
                                    font-weight: bold;
                                    font-size: 16px;
                                    color: #F8F8F8;
                                    line-height: 24px;
                                    text-align: left;
                                    font-style: normal;

                                    margin-bottom: 10px;
                                }
                                .theAwakeningOfMapNft_page_02_12412 {
                                    font-family: SpaceGrotesk, SpaceGrotesk;
                                    font-weight: bold;
                                    font-size: 16px;
                                    color: #F8F8F8;
                                    line-height: 24px;
                                    text-align: left;
                                    font-style: normal;
                                }
                            }
                        }
                        .theAwakeningOfMapNft_page_02_125 {
                            width: 100%;
                            height: 70px;
                            background: #C5F646;
                            border-radius: 16px;
                            cursor: pointer;

                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 24px;
                            color: #040404;
                            line-height: 70px;
                            text-align: center;
                            font-style: normal;
                            margin: 5px 0;
                        }
                        .theAwakeningOfMapNft_page_02_126 {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            .theAwakeningOfMapNft_page_02_12611 {
                                font-family: SpaceGrotesk, SpaceGrotesk;
                                font-weight: bold;
                                font-size: 16px;
                                color: #F8F8F8;
                                line-height: 24px;
                                text-align: left;
                                font-style: normal;

                                margin-bottom: 10px;
                            }
                        }
                    }
                }

                .theAwakeningOfMapNft_page_02_2 {
                    width: 70%;
                    margin-left: 15%;
                    margin-top: 80px;
                    margin-bottom: 80px;

                    font-family: SpaceGrotesk, SpaceGrotesk;
                    font-weight: 400;
                    font-size: 16px;
                    color: #F8F8F8;
                    line-height: 24px;
                    text-align: left;
                    font-style: normal;

                    white-space: pre-wrap;
                    -webkit-background-clip: text;

                    @media (max-width: 1023px) {
                        width: 80%;
                        margin-left: 10%;
                    }
                }
            }

            .theAwakeningOfMapNft_page_02_3 {
                width: 100%;
                /*height: 540px;*/
                background: #F8F8F8;
                border-radius: 60px 60px 0 0;
                .theAwakeningOfMapNft_page_02_30 {
                    width: 70%;
                    margin-left: 15%;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: 1023px){
                        width: 80%;
                        margin-left: 10%;

                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                    .theAwakeningOfMapNft_page_02_31 {
                        width: 45%;
                        margin-top: 50px;
                        margin-bottom: 60px;

                        @media (max-width: 1023px){
                            width: 100%;
                        }
                        .theAwakeningOfMapNft_page_02_311 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 48px;
                            color: #040404;
                            line-height: 61px;
                            text-align: left;
                            font-style: normal;

                            @media (max-width: 1023px){
                                font-size: 39px;
                            }
                        }
                        .theAwakeningOfMapNft_page_02_312 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: 400;
                            font-size: 16px;
                            color: #040404;
                            line-height: 24px;
                            text-align: left;
                            font-style: normal;

                            margin: 35px 0;
                        }
                        .theAwakeningOfMapNft_page_02_313 {
                            width: 180px;
                            height: 56px;
                            background: #040404;
                            border-radius: 16px;
                            cursor: pointer;

                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 16px;
                            color: #C5F646;
                            line-height: 56px;
                            text-align: center;
                            font-style: normal;
                        }
                    }
                    .theAwakeningOfMapNft_page_02_32 {
                        width: 45%;
                        margin-top: 50px;
                        margin-bottom: 60px;

                        @media (max-width: 1023px){
                            margin-top: 5px;
                            width: 100%;
                        }
                        .theAwakeningOfMapNft_page_02_321 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 48px;
                            color: #040404;
                            line-height: 61px;
                            text-align: left;
                            font-style: normal;

                            @media (max-width: 1023px){
                                font-size: 39px;
                            }
                        }
                        .theAwakeningOfMapNft_page_02_322 {
                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: 400;
                            font-size: 16px;
                            color: #040404;
                            line-height: 24px;
                            text-align: left;
                            font-style: normal;

                            margin: 35px 0;
                        }
                        .theAwakeningOfMapNft_page_02_323 {
                            width: 180px;
                            height: 56px;
                            background: #040404;
                            border-radius: 16px;
                            cursor: pointer;

                            font-family: SpaceGrotesk, SpaceGrotesk;
                            font-weight: bold;
                            font-size: 16px;
                            color: #C5F646;
                            line-height: 56px;
                            text-align: center;
                            font-style: normal;
                        }
                    }
                }
            }
        }
    }
</style>
